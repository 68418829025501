@font-face {
  font-family: 'dip-icons';
  src: url('fonts/dip-icons.eot?fbbstp');
  src:
    url('fonts/dip-icons.eot?fbbstp#iefix') format('embedded-opentype'),
    url('fonts/dip-icons.ttf?fbbstp') format('truetype'),
    url('fonts/dip-icons.woff?fbbstp') format('woff'),
    url('fonts/dip-icons.svg?fbbstp#dip-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='dip-'],
[class*=' dip-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dip-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='dip_label-'],
[class*=' dip_label-'] {
  letter-spacing: 1px;
  text-transform: uppercase !important;
  color: #fff;
  font-size: 9px;
  line-height: 12px;
  font-weight: 800;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap !important;
}

.dip-arrow-left:before {
  content: '\e900';
}
.dip-chevron-down:before {
  content: '\e901';
}
.dip-chevron-right:before {
  content: '\e902';
}
.dip-arrows:before {
  content: '\e903';
}
.dip-sort:before {
  content: '\e904';
}
.dip-size:before {
  content: '\e905';
}
.dip-eye-open:before {
  content: '\e906';
}
.dip-eye-closed:before {
  content: '\e907';
}
.dip-star:before {
  content: '\e908';
}
.dip-star-filled:before {
  content: '\e909';
}
.dip-info:before {
  content: '\e90a';
}
.dip-bell:before {
  content: '\e90b';
}
.dip-ok:before {
  content: '\e90c';
}
.dip-times:before {
  content: '\e90d';
}
.dip-hamburger:before {
  content: '\e90e';
}
.dip-bank:before {
  content: '\e911';
}
.dip-basket:before {
  content: '\e912';
}
.dip-calendar:before {
  content: '\e913';
}
.dip-camera:before {
  content: '\e914';
}
.dip-car:before {
  content: '\e915';
}
.dip-clock:before {
  content: '\e916';
}
.dip-directions:before {
  content: '\e917';
}
.dip-download:before {
  content: '\e918';
}
.dip-upload:before {
  content: '\e935';
}
.dip-edit:before {
  content: '\e919';
}
.dip-exclamation:before {
  content: '\e91a';
}
.dip-filter:before {
  content: '\e91c';
}
.dip-filter-circle:before {
  content: '\e91d';
}
.dip-palm:before {
  content: '\e924';
}
.dip-pinch:before {
  content: '\e928';
}
.dip-hand:before {
  content: '\e91e';
}
.dip-line:before {
  content: '\e91f';
}
.dip-megaphone:before {
  content: '\e921';
}
.dip-message:before {
  content: '\e922';
}
.dip-number:before {
  content: '\e923';
}
.dip-logo:before {
  content: '\e920';
}
.dip-bad-parcel:before {
  content: '\e910';
}
.dip-aggregation:before {
  content: '\e90f';
}
.dip-parcel:before {
  content: '\e925';
}
.dip-parcel-dropoff:before {
  content: '\e926';
}
.dip-figures:before {
  content: '\e91b';
}
.dip-phone:before {
  content: '\e927';
}
.dip-pin-local:before {
  content: '\e929';
}
.dip-plus:before {
  content: '\e92a';
}
.dip-team:before {
  content: '\e931';
}
.dip-profile-circle:before {
  content: '\e92b';
}
.dip-qr:before {
  content: '\e92c';
}
.dip-qrcode:before {
  content: '\e934';
}
.dip-search:before {
  content: '\e92d';
}
.dip-secure:before {
  content: '\e92e';
}
.dip-settings:before {
  content: '\e92f';
}
.dip-support:before {
  content: '\e930';
}
.dip-wallet:before {
  content: '\e932';
}
.dip-weight:before {
  content: '\e933';
}
input[type='number']:first-of-type::-webkit-inner-spin-button,
input[type='number']:first-of-type::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dip_label-asap {
  background: #fdb13f;
  color: #000000 !important;
}
.dip_label-draft {
  background: #6d2cbf;
}
.dip_label-needs_payment {
  background: #0c6019;
}
.dip_label-needs_fix {
  background: #a72002;
}
.dip_label-lost {
  background: #dedede;
  color: #000000 !important;
}
.dip_label-created {
  background: #ebff00;
  color: #000000 !important;
}
.dip_label-assigned {
  background: #36dbff;
  color: #000000 !important;
}
.dip_label-awaiting_rematch {
  background: #000000 !important;
  color: #ffffff !important;
}
.dip_label-delivered {
  background: #54cd84;
  color: #000000 !important;
}
.dip_label-out_for_delivery {
  background: #ffbb5f;
  color: #000000 !important;
}
.dip_label-rejected {
  background: transparent;
  border: 1px solid #ec1d05;
  color: #000000 !important;
}
.MuiTableCell-root a.MuiTypography-root:not(.disabled) {
  color: #0b21e4 !important;
  text-decoration-color: #0b21e4 !important;
}
